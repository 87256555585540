import React from "react"
import { graphql } from "gatsby"

import PagesLayout from '../components/pagesLayout'
import { useModalContextToggle } from './../components/modalContext'

import { usePlugins } from 'tinacms'
import { useRemarkForm } from 'gatsby-tinacms-remark'
import { InlineForm, InlineTextarea } from 'react-tinacms-inline'
import { InlineWysiwyg } from 'react-tinacms-editor'


const Faq = (props) => {
    const toggle = useModalContextToggle()
    const propsData = props.data.markdownRemark

    const formOptions = {
        fields: [
            
        ]
    }

    const [data, form] = useRemarkForm(propsData, formOptions)
    usePlugins(form)


    return (
        <PagesLayout>
            <InlineForm form={form} >
                <div className="banner_header">
                    <div className="uk-container">
                        <h1><InlineTextarea name="rawFrontmatter.title" /></h1>
                    </div>
                </div>
                <div id="faq-questions">
                    <div className="questions">

                        <InlineWysiwyg name="rawMarkdownBody" format="markdown">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data.html,
                                }}
                            />
                        </InlineWysiwyg>
                    </div>
                </div>
                <div className="banner_header lighter">
                    <div className="uk-container">
                        <h3>Earn Money Now</h3>
                        <div>
                            <div onClick={toggle} className="uk-button uk-button-primary" uk-toggle="#signup-modal">Get Started</div>
                        </div>
                    </div>
                </div>
            </InlineForm>
        </PagesLayout>
    )
}

export default Faq

export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    markdownRemark(frontmatter: { path: { eq: "/faq" } }) {
        ...TinaRemark
        html
        frontmatter {
            path
            title
        }
    }
}
`